import React, { FC } from "react";
import Image from "next/image";
import Link from "next/link";

interface NavProps {
    refCalls: any;
}

const Navbar: FC<NavProps> = ({
    refCalls: { aboutCall, workCall, expCall, contactCall },
}) => {
    return (
        <nav className="my-12 flex w-full items-center justify-center">
            <div className="mx-4 flex w-full flex-col items-center justify-between gap-3 sm:mx-6 sm:flex-row sm:gap-2 md:mx-12 lg:mx-0 lg:max-w-6xl">
                <Link href="/">
                    <a className="duration-100 hover:-translate-y-[0.2rem]">
                        <Image
                            src="/assets/logo.png"
                            height={50}
                            width={50}
                            alt="logo"
                        />
                    </a>
                </Link>
                <div className="inline-flex items-center gap-6 font-mono sm:gap-10">
                    <button
                        className="cursor-pointer text-blue-200 transition-all duration-100 ease-in-out hover:-translate-y-[0.1rem] hover:text-[#6df9ff]"
                        onClick={aboutCall}
                    >
                        About
                    </button>
                    <button
                        className="cursor-pointer text-blue-200 transition-all duration-100 ease-in-out hover:-translate-y-[0.1rem] hover:text-[#6df9ff]"
                        onClick={expCall}
                    >
                        Experience
                    </button>
                    <button
                        className="cursor-pointer text-blue-200 transition-all duration-100 ease-in-out hover:-translate-y-[0.1rem] hover:text-[#6df9ff]"
                        onClick={workCall}
                    >
                        Work
                    </button>
                    <button
                        className="cursor-pointer text-blue-200 transition-all duration-100 ease-in-out hover:-translate-y-[0.1rem] hover:text-[#6df9ff]"
                        onClick={contactCall}
                    >
                        Contact
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
