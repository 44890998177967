import React, { FC } from "react";
import Head from "next/head";
import SocialsSideBar from "@/components/Socials/SocialsSideBar";
import Footer from "@/components/Footer";
import { keywords, content, og_title, og_image, author } from "@/helpers/seo";

interface LayoutProps {
    title: string;
    content?: string;
    children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ title, children }) => {
    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="description" content={content} />
                <meta property="og:title" content={og_title} />
                <meta property="og:description" content={content} />
                <meta property="og:image" content={og_image} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content={author} />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <div className="flex w-full flex-col items-center justify-center bg-gray-900 text-white">
                <div className="h-full w-full px-6 sm:px-12 md:px-16 lg:max-w-5xl lg:px-6">
                    {children}
                </div>
                <SocialsSideBar />
            </div>
            <Footer />
        </>
    );
};

export default Layout;
