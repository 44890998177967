import React, { FC } from "react";
import { useState } from "react";
import { work } from "@/helpers/index";

const ExperienceBar: FC = () => {
    const [currState, setCurrState] = useState(0);

    const currCompany = work.filter((w) => w.id === currState)[0];

    const styles = {
        active: "flex items-center border-l-2 border-[#6df9ff] px-6 py-3 text-left transition-all duration-200 bg-[#6df9ff] bg-opacity-[3%] text-[#6df9ff]",
        inactive:
            "flex items-center border-l-2 border-[#6df9ff] border-opacity-25 px-6 py-3 text-left transition-all duration-200 hover:bg-[#6df9ff] hover:bg-opacity-[3%] text-blue-100 text-opacity-60",
    };

    return (
        <>
            <div className="my-12 flex gap-8">
                <div className="flex flex-col gap-0 font-mono text-[0.9rem] font-[500]">
                    {work.map((comp) => (
                        <button
                            key={comp.id}
                            className={
                                currState === comp.id
                                    ? styles.active
                                    : styles.inactive
                            }
                            onClick={() => setCurrState(comp.id)}
                        >
                            <h1>{comp.company_name}</h1>
                        </button>
                    ))}
                </div>
                <div className="flex flex-col gap-2 font-['Inter'] text-blue-100">
                    <h1 className="text-[1.2rem] font-bold">
                        {currCompany.role}
                        <span className="text-[#6df9ff]">
                            {" "}
                            @ {currCompany.company_full_name}
                        </span>
                    </h1>
                    <h1 className="font-mono text-[0.9rem] opacity-70">
                        {currCompany.timeline}
                    </h1>
                    <div className="my-2 text-[0.91rem] font-[400] text-blue-100 text-opacity-70">
                        {currCompany.experience.map((exp) => (
                            <h1 className="my-2" key={exp.id}>
                                <i className="fas fa-duotone fa-play mr-4 text-[0.7rem] text-[#6df9ff]"></i>
                                {exp.text}
                            </h1>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExperienceBar;
