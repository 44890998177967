import React, { FC } from "react";
import Project from "@/components/Projects/Project";
import { projects } from "@/helpers/index";

const ProjectList: FC = () => {
    return (
        <div className="">
            <div className="flex flex-col gap-12">
                {projects.map((item) => (
                    <Project key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default ProjectList;
