export const hashnode: string = "https://blog.sreekeshiyer.com";
export const github: string = "https://www.github.com/sreekeshiyer";
export const twitter: string = "https://www.twitter.com/sreekeshiyer";
export const instagram: string = "https://www.instagram.com/sreekeshiyer";
export const linkedin: string = "https://www.linkedin.com/in/sreekeshiyer";
export const buymeacoffee: string = "https://www.buymeacoffee.com/sreekeshiyer";

export const projects = [
    {
        id: 0,
        img: "https://res.cloudinary.com/zenon-cloudinary/image/upload/v1669490570/Project%20Images/166252702-7100586a-1a3c-496a-9f28-8a346a998c5d_t3weo7.png",
        title: "SKAN: Resume Parser",
        description:
            "A Resume Parser Service that is capable of parsing upto 100 resumes at a time along with options to store it for further use, providing information like contact details, education, work experience, skills and achievements, etc.",
        tech: ["Next.js", "TailwindCSS", "Supabase", "Flask", "NLTK"],
        github: "https://github.com/Parity-Boolean-Autocrats/skan-resume-parser",
        url: "",
    },
    {
        id: 1,
        img: "https://res.cloudinary.com/zenon-cloudinary/image/upload/v1674722052/Screenshot_622_zls2zc.png",
        title: "IntroAI",
        description:
            "An OpenAI-powered website that generates introductions and bios for various platforms.",
        tech: ["Next.js", "TailwindCSS", "OpenAI API"],
        github: "https://github.com/sreekeshiyer/introai",
        url: "https://www.introai.me/",
    },
    {
        id: 2,
        img: "https://res.cloudinary.com/zenon-cloudinary/image/upload/v1669490581/Project%20Images/149094668-54f23a54-102c-43cb-844b-569923de3c99_osmrhn.png",
        title: "Edulink",
        description:
            "A web-application which brings the best learning content that is available on YouTube. It aims at creating an ecosystem for students and budding developers, providing them the best content without involving the YouTube algorithm.",
        tech: ["Next.js", "Supabase", "YouTube Data API v3"],
        github: "https://github.com/sreekeshiyer/edulink",
        url: "https://www.edulink.vercel.app",
    },
    // {
    //     id: 3,
    //     img: "https://res.cloudinary.com/zenon-cloudinary/image/upload/v1632114088/Project%20Images/item16_crkaug.png",
    //     title: "KNSS: Examination System",
    //     description:
    //         "Create and participate in online examinations, across different subjects and monitor student progress, all in one place",
    //     tech: ["Python", "Flask", "MySQL", "BeautifulSoup"],
    //     github: "https://github.com/VKrishna2090/KNSS-Aptitude",
    //     url: "",
    // },
];

export const work = [
    {
        id: 0,
        company_name: "Placement Int.",
        company_full_name: "Placement International",
        role: "Freelance Backend Developer",
        timeline: "March 2023 - May 2023",
        experience: [
            {
                id: 0,
                text: "Creating a REST API that makes use of OpenAI's API and Amazon Textract to extract useful information from Resumes.",
            },
            {
                id: 1,
                text: "Removed dependency on third-party API that costed ~7000€ per year, to a first party solution on AWS cloud that now only costs 600€ per year.",
            },
        ],
    },
    {
        id: 1,
        company_name: "SUD Life",
        company_full_name: "Star Union Dai-ichi Life Insurance",
        role: "Lead Software Developer",
        timeline: "Jan 2022 - Present",
        experience: [
            {
                id: 0,
                text: "Leading a team working on a Software Product to automate data validation for multiple insurance policies.",
            },
            {
                id: 1,
                text: "Managing Products complying with finance requirements.",
            },
        ],
    },
    {
        id: 2,
        company_name: "Garena",
        company_full_name: "Garena",
        role: "eSports Content Writer",
        timeline: "Jun 2020 - Sep 2020",
        experience: [
            {
                id: 0,
                text: "Reporting the latest Free Fire eSports events.",
            },
            {
                id: 1,
                text: "Covering key highlights of important games in the Indian contingent.",
            },
        ],
    },
    {
        id: 3,
        company_name: "Realme",
        company_full_name: "realme India Community",
        role: "Content Writer",
        timeline: "Sep 2019 - Oct 2020",
        experience: [
            {
                id: 0,
                text: "Making Contributions to Product Knowledge Base and gathering User Feedback.",
            },
            {
                id: 1,
                text: "Author of more than 50 threads in the Community.",
            },
        ],
    },
];

export const awards = [
    {
        id: 0,
        title: "CodeForGood 2022 Winner",
        url: "https://res.cloudinary.com/zenon-cloudinary/image/upload/v1669498597/Project%20Images/Screenshot_4_rnqkct.png",
    },
    {
        id: 1,
        title: "Hashnode 4Articles4Weeks Writeathon Runner Up",
        url: "https://townhall.hashnode.com/4articles4weeks-writeathon-the-winners",
    },
    {
        id: 2,
        title: "Realme Content Contributors 2019",
        url: "https://res.cloudinary.com/zenon-cloudinary/image/upload/v1669498763/Project%20Images/Realme_Community_Contributor_u6ctpu.jpg",
    },
];
