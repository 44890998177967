import React, { FC } from "react";

const Footer: FC = () => {
    return (
        <div className="relative w-full bg-gray-900 pb-4">
            <p className="text-center font-mono text-blue-100 text-opacity-70">
                {" "}
                Copyright &copy; {new Date().getFullYear()}. All Rights Reserved
            </p>
        </div>
    );
};

export default Footer;
