/* eslint-disable @next/next/no-img-element */
import React, { FC } from "react";

interface ProjectProps {
    item: {
        id: number;
        img: string;
        title: string;
        description: string;
        tech: string[];
        github: string;
        url: string;
    };
}

const styles = {
    even: {
        outerFlex:
            "hidden md:flex items-center flex-row-reverse justify-center gap-4",
        innerFlex: "flex flex-col items-start justify-between w-full",
        paragraph: "max-w-[28rem] text-[0.9rem] text-blue-100",
    },
    odd: {
        outerFlex: "hidden md:flex items-center justify-center gap-4",
        innerFlex: "flex flex-col items-end justify-between w-full",
        paragraph:
            "max-w-[28rem] text-right text-[0.9rem] text-blue-100 flex flex-col items-end",
    },
};

const Project: FC<ProjectProps> = ({ item }) => {
    return (
        <>
            <div
                className={
                    (item.id + 1) % 2 === 0
                        ? styles.even.outerFlex
                        : styles.odd.outerFlex
                }
            >
                <div className="relative h-full w-full before:absolute before:top-0 before:left-0 before:h-full before:w-full before:rounded-md before:bg-blue-500 before:bg-opacity-30 before:transition-all before:duration-200 before:ease-in-out before:content-[''] hover:before:bg-opacity-0">
                    <img
                        src={item.img}
                        className="rounded-md"
                        alt={item.title}
                    />
                </div>
                <div
                    className={
                        (item.id + 1) % 2 === 0
                            ? styles.even.innerFlex
                            : styles.odd.innerFlex
                    }
                >
                    <h1 className="font-mono text-[#6df9ff]">
                        Featured Project
                    </h1>
                    <h1 className="my-2 text-3xl font-bold text-blue-200">
                        {item.title}
                    </h1>
                    <div
                        className={
                            (item.id + 1) % 2 === 0
                                ? styles.even.paragraph
                                : styles.odd.paragraph
                        }
                    >
                        <p className="my-2">{item.description}</p>
                        <div className="my-4 flex gap-3 font-mono text-blue-100">
                            {item.tech.map((t) => (
                                <p key={item.tech.indexOf(t)}>{t}</p>
                            ))}
                        </div>
                        <div className="flex gap-2">
                            <a
                                href={item.github}
                                className="text-white transition-all duration-100 ease-in-out hover:text-[#6df9ff]"
                            >
                                <i className="fa-brands fa-github"></i>
                            </a>
                            {item.url === "" ? (
                                <></>
                            ) : (
                                <a
                                    href={item.url}
                                    className="text-white transition-all duration-100 ease-in-out hover:text-[#6df9ff]"
                                >
                                    <i className="fas fa-solid fa-link"></i>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="md:hidden"
                style={{
                    background: `url(${item.img}) center center/cover`,
                }}
            >
                <div className="bg-gray-900 bg-opacity-90 p-6 xs:p-12">
                    <div className="">
                        <h1 className="font-mono text-[#6df9ff]">
                            Featured Project
                        </h1>
                        <h1 className="my-4 text-3xl font-bold text-blue-200">
                            {item.title}
                        </h1>
                        <div className="max-w-full text-[0.9rem] text-blue-100">
                            <p className="my-4">{item.description}</p>
                            <div className="my-4 flex w-full flex-wrap gap-2 font-mono text-blue-100">
                                {item.tech.map((t) => (
                                    <p key={item.tech.indexOf(t)}>{t}</p>
                                ))}
                            </div>
                            <div className="flex items-center gap-4 text-[1rem]">
                                <a
                                    href={item.github}
                                    className="text-white transition-all duration-100 ease-in-out hover:text-[#6df9ff]"
                                >
                                    <i className="fa-brands fa-github"></i>
                                </a>
                                {item.url === "" ? (
                                    <></>
                                ) : (
                                    <a
                                        href={item.url}
                                        className="text-white transition-all duration-100 ease-in-out hover:text-[#6df9ff]"
                                    >
                                        <i className="fas fa-solid fa-link"></i>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Project;
