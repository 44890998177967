import React, { FC } from "react";
import Socials from "@/components/Socials/Socials";
import { hashnode } from "@/helpers/index";

const Home: FC = () => {
    return (
        <div className="flex h-full flex-col justify-between gap-20 lg:my-40 lg:justify-start lg:px-0">
            <Socials />
            <div>
                <h1 className="font-mono text-[#6df9ff]">Hi, my name is</h1>
                <h1 className="mt-0 text-4xl font-bold text-blue-200 sm:my-6 sm:text-7xl">
                    Sreekesh Iyer
                </h1>
                <h1 className="my-4 max-w-[80%] text-3xl font-bold text-blue-100 opacity-80 sm:text-5xl">
                    Software Developer, Content Writer
                </h1>
                <p className="my-12 max-w-[80%] font-['Inter'] text-[0.9rem] font-normal leading-7 text-blue-100 opacity-70 sm:w-[600px] sm:text-justify sm:text-[0.97rem]">
                    I&apos;m a Software Engineer specialising in
                    Web Development and DevOps. I enjoy learning about different new aspects of technology every day. 
                </p>
                <div className="">
                    <a
                        className="rounded-md border-2 border-[#6df9ff] p-4 text-[#6df9ff] transition-all duration-200 ease-in-out hover:bg-[#6df9ff] hover:bg-opacity-20"
                        target="_blank"
                        rel="noreferrer"
                        href={hashnode}
                    >
                        Check out my Blog!
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Home;
