import React, { FC } from "react";
import Image from "next/image";
import {
    github,
    instagram,
    twitter,
    linkedin,
    buymeacoffee,
} from "@/helpers/index";

const SocialsSideBar: FC = ({}) => {
    return (
        <>
            <div className="sticky left-14 bottom-16 hidden self-start lg:inline-block">
                <div className="flex flex-col items-center justify-center gap-3 text-blue-200">
                    <a
                        className="text-[1.5rem] transition-all duration-100 ease-in-out hover:-translate-y-[0.3rem] hover:text-[#6df9ff]"
                        href={github}
                        aria-label="My GitHub Profile"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fa-brands fa-github"></i>
                    </a>
                    <a
                        className="text-[1.5rem] transition-all duration-100 ease-in-out hover:-translate-y-[0.3rem] hover:text-[#6df9ff]"
                        href={instagram}
                        aria-label="My Instagram Profile"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a
                        className="text-[1.5rem] transition-all duration-100 ease-in-out hover:-translate-y-[0.3rem] hover:text-[#6df9ff]"
                        href={twitter}
                        aria-label="My Twitter Profile"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a
                        className="text-[1.5rem] transition-all duration-100 ease-in-out hover:-translate-y-[0.3rem] hover:text-[#6df9ff]"
                        href={linkedin}
                        aria-label="My Linkedin Profile"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href={buymeacoffee} className="mt-1" target="_blank" rel="noreferrer">
                        <span className="text-[1.1rem] text-gray-400">
                            <Image
                                src="/assets/bmac.png"
                                width={19.47}
                                height={28}
                                alt="BuyMeACoffee"
                            />
                        </span>
                    </a>
                </div>
            </div>
        </>
    );
};

export default SocialsSideBar;
