import React, { FC } from "react";
import Image from "next/image";

const Contact: FC = () => {
    return (
        <>
            <div className="flex h-full w-full flex-col items-center justify-center gap-10 lg:my-40 lg:justify-start">
                <div className="flex w-full flex-col items-center justify-center text-center font-['Inter']">
                    <h1 className="mt-10 text-5xl font-bold text-blue-200 ">
                        Get In Touch
                    </h1>
                    <p className="mt-10 w-[300px] text-blue-100 text-opacity-70 sm:w-[600px]">
                        I check my emails once in two days, you can reach out for any questions, guidance, promotions or collaborations, etc. I am currently not open to freelance work. 
                    </p>
                </div>
                <div className="my-2">
                    <a
                        className="rounded-md border-2 border-[#6df9ff] p-4 text-[#6df9ff] transition-all duration-200 ease-in-out hover:bg-[#6df9ff] hover:bg-opacity-20"
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:sreekeshiyer@gmail.com"
                    >
                        Say Hello!
                    </a>
                </div>
            </div>
            <div className="my-6"></div>
        </>
    );
};

export default Contact;
