import type { NextPage } from "next";
import Layout from "@/components/Layout";
import Navbar from "@/components/Navbar";
import Home from "@/components/Home";
import About from "@/components/About/About";
import Experience from "@/components/Experience/Experience";
import Work from "@/components/Projects/Work";
import Contact from "@/components/Contact/Contact";

import { useRef } from "react";

const HomePage: NextPage = () => {
    const aboutRef: React.MutableRefObject<any> = useRef(null);
    const workRef: React.MutableRefObject<any> = useRef(null);
    const expRef: React.MutableRefObject<any> = useRef(null);
    const contactRef: React.MutableRefObject<any> = useRef(null);

    const aboutCall = () =>
        aboutRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });

    const workCall = () =>
        workRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });

    const expCall = () => {
        expRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });
    };

    const contactCall = () =>
        contactRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });

    return (
        <Layout title="Sreekesh Iyer" key="desc">
            <Navbar refCalls={{ aboutCall, workCall, expCall, contactCall }} />
            <Home />
            <div id="#about" ref={aboutRef} style={{ height: 0 }} />
            <div className="my-20"></div>
            <About />
            <div id="#exp" ref={expRef} style={{ height: 0 }} />
            <div className="my-20"></div>
            <Experience />
            <div id="#work" ref={workRef} style={{ height: 0 }} />
            <Work />
            <div className="my-12"></div>
            <div id="#contact" ref={contactRef} style={{ height: 0 }} />
            <Contact />
        </Layout>
    );
};

export default HomePage;
