/* eslint-disable @next/next/no-img-element */
import React, { FC } from "react";
import { hashnode, awards } from "@/helpers/index";

const About: FC = () => {
    return (
        <>
            <div className="flex h-full w-full flex-col gap-20 lg:mt-40 lg:mb-10 lg:justify-start">
                <div className="w-full">
                    <h1 className="text-center text-3xl font-bold text-blue-200 after:relative after:-top-4 after:ml-40 after:block after:h-[0.5px] after:w-0 after:bg-blue-300 after:bg-opacity-20 after:content-[''] sm:text-left sm:after:w-80 xs:text-left xs:after:w-1/3">
                        About Me
                    </h1>
                    <div className="my-12 flex w-full flex-col-reverse items-center justify-between gap-6 lg:flex-row lg:gap-12">
                        <div className="flex-1 self-start ">
                            <p className="font-['Inter'] text-[0.9rem] leading-7 text-blue-100 opacity-70 sm:text-justify sm:text-[0.97rem]">
                                Hello there! I am Sreekesh Iyer. My work and expertise
                                revolve around technology. I am a Software Engineer by profession, but I&apos;m also a Technical Writer, a Podcaster and a Community Manager purely from interest and curiosity.
                            </p>
                            <p className="my-4 font-[&apos;Inter'] text-[0.9rem]  leading-7 text-blue-100 opacity-70 sm:text-justify sm:text-[0.97rem]">
                                As much as I love tech, I love tech communities too. Nothing matches the energy I feel during meetups and conferences when I meet people. You can follow what I learn on my journey via my
                                {" "}
                                <a
                                    className="link link-underline link-underline-black relative inline-block cursor-pointer text-[#6df9ff] brightness-125 contrast-100 saturate-200"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={hashnode}
                                >
                                    blog
                                </a>
                                . I am currently working for{" "}
                                <a
                                    className="link link-underline link-underline-black relative inline-block cursor-pointer text-[#6df9ff] brightness-125 contrast-100 saturate-200"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://jpmorganchase.com/"
                                >
                                    JP Morgan Chase & Co.
                                </a>
                                {" "}as a Software Engineer.
                            </p>
                            <div>
                                <p className="my-4 font-['Inter'] text-[0.9rem] leading-7 text-blue-100 opacity-70 sm:text-justify sm:text-[0.97rem]">
                                    Here are some of the technologies I&apos;ve
                                    been working with recently:
                                </p>
                                <ul className="my-4 grid grid-cols-2 font-mono text-[0.9rem] leading-7 text-blue-100 opacity-70 sm:text-justify sm:text-[0.97rem]">
                                    <li className="before:text-leading-7 before:relative before:left-0 before:mr-2 before:text-xl before:text-[#6df9ff] before:content-['→']">
                                        Next.js
                                    </li>
                                    <li className="before:text-leading-7 before:relative before:left-0 before:mr-2 before:text-xl before:text-[#6df9ff] before:content-['→']">
                                        Python
                                    </li>
                                    <li className="before:text-leading-7 before:relative before:left-0 before:mr-2 before:text-xl before:text-[#6df9ff] before:content-['→']">
                                        AWS
                                    </li>
                                    <li className="before:text-leading-7 before:relative before:left-0 before:mr-2 before:text-xl before:text-[#6df9ff] before:content-['→']">
                                        Docker
                                    </li>
                                    <li className="before:text-leading-7 before:relative before:left-0 before:mr-2 before:text-xl before:text-[#6df9ff] before:content-['→']">
                                        Supabase
                                    </li>
                                    <li className="before:text-leading-7 before:relative before:left-0 before:mr-2 before:text-xl before:text-[#6df9ff] before:content-['→']">
                                        Terraform
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex flex-1 items-center justify-center">
                            <img
                                src="/assets/profile.png"
                                className="h-2/3 w-2/3 overflow-hidden rounded-full border-4 border-[#6df9ff] lg:h-3/4 lg:w-3/4"
                                alt="profile image"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex w-full flex-wrap items-start justify-center gap-12">
                {awards.map((aw) => (
                    <div
                        className="flex flex-col items-center gap-2 font-['Inter']"
                        key={aw.id}
                    >
                        <i className="fa-solid fa-award mb-4 text-5xl text-[#6df9ff]"></i>
                        <a
                            href={aw.url}
                            className="inline-block cursor-pointer text-center transition-colors duration-200 hover:text-blue-100"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {aw.title}
                        </a>
                    </div>
                ))}
            </div>
        </>
    );
};

export default About;
