import React, { FC } from "react";
import ProjectList from "./ProjectList";

const Work: FC = () => {
    return (
        <>
            <div className="flex h-full w-full flex-col gap-20 lg:my-40 lg:justify-start">
                <div className="w-full">
                    <h1 className="text-center text-3xl font-bold text-blue-200 after:relative after:-top-4 after:ml-36 after:block after:h-[0.5px] after:w-0 after:bg-blue-300 after:bg-opacity-20 after:content-[''] sm:text-left sm:after:w-60 md:after:w-80 xs:text-left xs:after:w-1/3">
                        My Work
                    </h1>
                    <div className="my-12"></div>
                    <ProjectList />
                </div>
            </div>
        </>
    );
};

export default Work;
